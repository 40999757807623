import React, { forwardRef, FunctionComponent, RefAttributes } from 'react';
import styles from './socialMediaInput.module.scss';
import { ReactComponent as LinkedInIcon } from '../../../assets/icons/icons_UI/linkedin_24dp.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/icons_UI/facebook_24dp.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/icons/icons_UI/twitter_24dp.svg';
import { ReactComponent as HttpIcon } from '../../../assets/icons/icons_UI/http_24dp.svg';
import { Input } from 'primitives/Inputs/Input';
import { InputProps } from '../Input/Input';

export interface SocialMediaInputProps extends InputProps {
	type: 'web' | 'linkedin' | 'twitter' | 'facebook';
	className?: string;
}

const iconsByType = {
	web: <HttpIcon />,
	linkedin: <LinkedInIcon />,
	twitter: <TwitterIcon />,
	facebook: <FacebookIcon />,
};

const SocialMediaInput: FunctionComponent<
	SocialMediaInputProps & RefAttributes<any>
> = forwardRef<any, SocialMediaInputProps>(
	({ type, className = '', ...inputProps }, ref) => {
		return (
			<div className={`${styles.container} ${className}`}>
				<Input {...inputProps} labelWithin ref={ref} />
				<span className={styles.icon}>{iconsByType[type]}</span>
			</div>
		);
	},
);

SocialMediaInput.displayName = 'SocialMediaInput';
export default SocialMediaInput;
