import { FunctionComponent } from 'react';
import styles from './label.module.scss';
type LabelProps = {
	label: string | null;
};

const Label: FunctionComponent<LabelProps> = ({ label }: LabelProps) => {
	if (!label) return null;

	const starIncluded = label.includes('*');
	if (starIncluded) {
		const [labelName] = label.split('*');
		return (
			<span>
				{labelName}
				<span className={styles.required}>*</span>
			</span>
		);
	}
	return <>{label}</>;
};

export default Label;
