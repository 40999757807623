import IconRadioOptionType from 'primitives/Inputs/IconRadioButtons/IconRadioOptionType';
import { Label } from 'primitives/Label';
import { forwardRef, FunctionComponent, RefAttributes, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './multiChoice.module.scss';
export interface MultiChoiceProps {
	label: string;
	onChange?: (value: any) => void;
	value?: any[];
	errorMessage?: string;
	options: IconRadioOptionType[];
	className?: string;
	disabled?: boolean;
	[otherProps: string]: any;
}

const MultiChoice: FunctionComponent<MultiChoiceProps & RefAttributes<any>> =
	forwardRef<any, MultiChoiceProps>(
		(
			{
				label,
				onChange,
				value,
				options,
				errorMessage,
				className = '',
				disabled,
				...otherProps
			},
			ref,
		) => {
			const [legendNodeId] = useState(uuidv4());

			const getNewValue = (optionValue: any) => {
				if (!value) return [optionValue];
				const optionChosen = value.includes(optionValue);
				return optionChosen
					? value.filter((item) => item !== optionValue)
					: [...value, optionValue];
			};

			return (
				<div className={`${styles.container} ${className}`}>
					<fieldset disabled={disabled}>
						<legend id={legendNodeId}>
							<Label label={label} />
						</legend>
						<div className={styles.optionsContainer}>
							{options.map(
								(
									{
										label,
										value: optionValue,
										icon,
										disabled,
									},
									index,
								) => (
									<label
										key={index}
										className={styles.option}
									>
										<input
											type="checkbox"
											value={optionValue}
											onChange={() =>
												onChange &&
												onChange(
													getNewValue(optionValue),
												)
											}
											checked={
												value &&
												value.includes(optionValue)
											}
											disabled={disabled}
											{...otherProps}
											ref={ref}
										/>
										<span
											className={
												styles.iconAndLabelContainer
											}
										>
											<span
												className={`${styles.icon} ${
													errorMessage && styles.error
												}`}
											>
												{icon}
											</span>
											<span className={styles.text}>
												{label}
											</span>
										</span>
									</label>
								),
							)}
						</div>
					</fieldset>
					{errorMessage && (
						<div
							className={styles.errorMessage}
							aria-describedby={legendNodeId}
						>
							{errorMessage}
						</div>
					)}
				</div>
			);
		},
	);

MultiChoice.displayName = 'MultiChoice';
export default MultiChoice;
