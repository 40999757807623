import { Label } from 'primitives/Label';
import { forwardRef, FunctionComponent, RefAttributes, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './radioButtons.module.scss';
import RadioOptionType from './RadioOptionType';

export interface RadioButtonsProps {
	label: string;
	onChange: (value: any) => void;
	value?: any;
	errorMessage?: string | null;
	options: RadioOptionType[];
	className?: string;
	hideLabel?: boolean;
	disabled?: boolean;
	[otherProps: string]: any;
}

const RadioButtons: FunctionComponent<RadioButtonsProps & RefAttributes<any>> =
	forwardRef<any, RadioButtonsProps>(
		(
			{
				label,
				onChange,
				value,
				options,
				errorMessage,
				className = '',
				hideLabel = false,
				disabled = false,
				...otherProps
			},
			ref,
		) => {
			const [legendNodeId] = useState(uuidv4());
			return (
				<div className={`${styles.container} ${className}`}>
					<fieldset disabled={disabled}>
						<legend
							id={legendNodeId}
							className={hideLabel ? styles.hidden : ''}
						>
							<Label label={label} />
						</legend>
						<div className={styles.optionsContainer}>
							{options.map(
								(
									{ label, value: optionValue, disabled },
									index,
								) => (
									<label
										key={index}
										className={styles.option}
									>
										<input
											type="radio"
											value={optionValue}
											onChange={() =>
												onChange(optionValue)
											}
											checked={value === optionValue}
											disabled={disabled}
											{...otherProps}
											ref={ref}
										/>
										<span
											className={`${styles.text} ${
												errorMessage && styles.error
											}`}
										>
											{label}
										</span>
									</label>
								),
							)}
						</div>
					</fieldset>
					{errorMessage && (
						<div
							className={styles.errorMessage}
							aria-describedby={legendNodeId}
						>
							{errorMessage}
						</div>
					)}
				</div>
			);
		},
	);
RadioButtons.displayName = 'RadioButtons';
export default RadioButtons;
