import React, { forwardRef, FunctionComponent, RefAttributes } from 'react';
import { Input } from '../Input';
import styles from './Search.module.scss';
import { ReactComponent as SearchIcon } from '../../../assets/icons/icons_UI/search_24dp.svg';

export interface ISearchProps {
	onChange: (e: any) => void;
	placeholder?: string;
	label?: string;
	className?: string;
	value?: any;
	[otherProps: string]: any;
}

const Search: FunctionComponent<ISearchProps & RefAttributes<any>> = forwardRef<
	any,
	ISearchProps
>(
	(
		{
			onChange,
			placeholder = 'Search...',
			label = 'Search',
			className = '',
			value,
			...otherProps
		},
		ref,
	) => {
		return (
			<div className={`${styles.container} ${className}`}>
				<Input
					ref={ref}
					onChange={onChange}
					placeholder={placeholder}
					label={label}
					hideLabel={true}
					value={value}
					{...otherProps}
				/>
				<SearchIcon className={styles.icon} />
			</div>
		);
	},
);

Search.displayName = 'Search';
export default Search;
