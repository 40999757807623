import {
	ReactElement,
	useState,
	forwardRef,
	FunctionComponent,
	RefAttributes,
} from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import 'moment/locale/en-gb';
import { v4 as uuidv4 } from 'uuid';
import styles from './dateTimePicker.module.scss';
import { Tooltip } from 'primitives/Tooltip';
import { TooltipContentSizeType } from 'primitives/Tooltip/TooltipContentSizeType';
import { Label } from 'primitives/Label';
import { ReactComponent as CalendarIcon } from 'assets/icons/icons_UI/calendar_24dp.svg';

export interface DateTimePickerProps {
	label: string;
	onChange?: (event: any) => void;
	value?: any;
	errorMessage?: string;
	placeholder?: string;
	className?: string;
	withTime?: boolean;
	tooltip?: string | ReactElement;
	tooltipContentSize?: TooltipContentSizeType;
	isValidDate?: (currentDate: any, selectedDate: any) => boolean;
	timeConstraints?: Datetime.TimeConstraints;
	initialViewDate?: string | Date | moment.Moment;
	utc?: boolean;
	disabled?: boolean;
	dropCalendarUpward?: boolean;
	readonly?: boolean;
	showIcon?: boolean;
	[otherProps: string]: any;
}

const DateTimePicker: FunctionComponent<
	DateTimePickerProps & RefAttributes<any>
> = forwardRef<any, DateTimePickerProps>(
	(
		{
			label,
			onChange,
			value,
			withTime = false,
			errorMessage,
			tooltip,
			tooltipContentSize,
			placeholder,
			isValidDate,
			timeConstraints,
			initialViewDate,
			className,
			utc = false,
			disabled = false,
			dropCalendarUpward = false,
			readonly = false,
			showIcon = false,
			...otherProps
		},
		ref,
	) => {
		const [inputNodeId] = useState(uuidv4());
		const [labelNodeId] = useState(uuidv4());
		const defaultPlaceholder = withTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';

		const renderInput = (props: any) => {
			return (
				<>
					<input
						id={inputNodeId}
						{...props}
						className={`${props.className} ${
							errorMessage ? 'error' : ''
						} ${showIcon ? 'withIcon' : ''}`}
						value={value ? props.value : ''}
						placeholder={placeholder || defaultPlaceholder}
						disabled={disabled}
						readOnly={readonly}
					/>
					{showIcon && (
						<div className={styles.icon}>{<CalendarIcon />}</div>
					)}
				</>
			);
		};

		return (
			<div className={`${className} ${styles.container}`}>
				<label
					htmlFor={inputNodeId}
					id={labelNodeId}
					className={`${styles.label} ${disabled && styles.disabled}`}
				>
					<Label label={label} />
				</label>
				<div className={styles.inputAndTooltipContainer}>
					<Datetime
						className={`react-datetime ${
							dropCalendarUpward ? 'dropUpward' : ''
						}`}
						dateFormat={'DD/MM/YYYY'}
						utc={utc}
						locale="en-gb"
						timeFormat={withTime}
						renderInput={renderInput}
						closeOnSelect
						isValidDate={isValidDate}
						timeConstraints={timeConstraints}
						initialViewDate={initialViewDate}
						onChange={onChange}
						value={value}
						ref={ref}
						{...otherProps}
					/>
					{tooltip && (
						<Tooltip
							className={styles.tooltip}
							contentSize={tooltipContentSize}
						>
							{tooltip}
						</Tooltip>
					)}
				</div>
				{errorMessage && (
					<div
						className={styles.errorMessage}
						aria-describedby={labelNodeId}
					>
						{errorMessage}
					</div>
				)}
			</div>
		);
	},
);

DateTimePicker.displayName = 'DateTimePicker';
export default DateTimePicker;
