import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styles from './tooltip.module.scss';
import { ReactComponent as HelpIcon } from '../../assets/icons/help_white.svg';
import { TooltipContentSizeType } from './TooltipContentSizeType';

export interface TooltipProps {
	children: React.ReactElement | string;
	icon?: React.ReactElement;
	className?: string;
	contentSize?: TooltipContentSizeType;
}

//TODO: style properly
const Tooltip: FunctionComponent<TooltipProps> = ({
	children,
	icon = <HelpIcon />,
	className,
	contentSize = 'normal',
}) => {
	const [showTooltip, setShowToolTip] = useState(false);
	const [coords, setCoords] = useState({ top: 0 });
	const wrapperRef = useRef<HTMLSpanElement>(null);

	useEffect(() => {
		if (
			wrapperRef.current?.offsetHeight != null &&
			coords.top !== wrapperRef.current?.offsetHeight
		) {
			setCoords({ top: wrapperRef.current?.offsetHeight });
		}
	});

	return (
		<span
			ref={wrapperRef}
			className={`${styles.tooltip} ${
				showTooltip ? '' : styles.hidden
			} ${className}`}
			onMouseLeave={() => setShowToolTip(false)}
			onMouseEnter={() => setShowToolTip(true)}
			onFocus={() => setShowToolTip(true)}
			onBlur={() => setShowToolTip(false)}
		>
			<span
				style={{ bottom: coords.top + 10 }}
				className={`${styles.tooltipContent} ${styles[contentSize]}`}
			>
				{children}
			</span>
			<span className={styles.icon} tabIndex={0}>
				{icon}
			</span>
		</span>
	);
};

export default Tooltip;
