import {
	forwardRef,
	FunctionComponent,
	ReactElement,
	RefAttributes,
	useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './select.module.scss';
import ReactSelect, { components } from 'react-select';
import SelectOptionType from './SelectOptionType';
import { Tooltip } from 'primitives/Tooltip';
import { TooltipContentSizeType } from 'primitives/Tooltip/TooltipContentSizeType';
import { Label } from 'primitives/Label';

export interface SelectProps {
	label: string;
	onChange?: (value: any) => void;
	value?: any;
	options: SelectOptionType[];
	errorMessage?: string | null | boolean;
	errorWithoutMessage?: boolean;
	placeholder?: string;
	disabled?: boolean;
	isClearable?: boolean;
	isMulti?: boolean;
	id?: string;
	className?: string;
	tooltip?: string | ReactElement;
	tooltipContentSize?: TooltipContentSizeType;
	labelWithin?: boolean;
	hideLabel?: boolean;
	onBlur?: (event: any) => void;
	[otherProps: string]: any;
}

const Select: FunctionComponent<SelectProps & RefAttributes<any>> = forwardRef<
	any,
	SelectProps
>(
	(
		{
			label,
			onChange,
			value,
			isMulti,
			options,
			isClearable,
			disabled,
			errorMessage,
			errorWithoutMessage,
			placeholder,
			className = '',
			tooltip,
			tooltipContentSize,
			labelWithin = false,
			hideLabel = false,
			onBlur,
			components,
			...otherProps
		},
		ref,
	) => {
		const [inputId] = useState(uuidv4());
		const [labelId] = useState(uuidv4());
		return (
			<div
				className={`${styles.container} ${className} ${
					labelWithin ? styles.labelWithin : styles.labelOver
				}`}
			>
				<div
					className={`${styles.inputAndTooltipContainer} ${
						labelWithin ? styles.labelWithin : styles.labelOver
					}`}
				>
					<ReactSelect
						inputId={inputId}
						className={`react-select-container ${
							(errorMessage || errorWithoutMessage) && 'error'
						} ${labelWithin ? 'labelWithin' : 'labelOver'}`}
						classNamePrefix="react-select"
						isMulti={isMulti}
						options={options}
						isClearable={isClearable}
						isDisabled={disabled}
						placeholder={placeholder}
						label={label}
						labelId={labelId}
						components={{ Control, ...components }}
						labelOver={!labelWithin}
						hideLabel={hideLabel}
						onChange={onChange}
						onBlur={onBlur}
						value={value}
						ref={ref}
						{...otherProps}
					/>
					{tooltip && (
						<Tooltip
							className={styles.tooltip}
							contentSize={tooltipContentSize}
						>
							{tooltip}
						</Tooltip>
					)}
				</div>
				{errorMessage && (
					<div
						className={styles.errorMessage}
						aria-describedby={labelId}
					>
						{errorMessage}
					</div>
				)}
			</div>
		);
	},
);

const Control: FunctionComponent<any> = (props: any) => {
	const { isFocused, hasValue, selectProps } = props;
	const shouldFloat = isFocused || hasValue;
	const lowerContrast = !isFocused && hasValue;
	const { label, labelId, inputId, labelOver, hideLabel } = selectProps;

	return (
		<>
			<label
				htmlFor={inputId}
				id={labelId}
				className={`${styles.label} ${
					labelOver ? styles.over : styles.within
				} ${hideLabel && styles.hidden} ${
					shouldFloat && styles.floating
				} ${lowerContrast && styles.lowerContrast} `}
			>
				<Label label={label} />
			</label>
			<components.Control {...props} />
		</>
	);
};

Select.displayName = 'Select';
export default Select;
