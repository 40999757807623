// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getValueFromLocalStorage = (
	key: string,
	defaultValue: any,
): any => {
	try {
		const data =
			localStorage &&
			localStorage.hasOwnProperty(key) &&
			localStorage.getItem(key);
		return data ? JSON.parse(data) : defaultValue;
	} catch (e) {
		return defaultValue;
	}
};
