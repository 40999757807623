import React, { FunctionComponent } from 'react';
import styles from './multiInput.module.scss';
import { ReactComponent as XIcon } from '../../../assets/icons/icons_navigation/close_24dp.svg';

interface ValuesChipsProps {
	values: string[];
	removeValue: (arg: number) => void;
}

const ValuesChips: FunctionComponent<ValuesChipsProps> = ({
	values,
	removeValue,
}) => {
	return (
		<ul className={styles.chipsContainer}>
			{values.map((item, index) => (
				<li key={index} className={styles.chip}>
					<span>{item}</span>
					<button
						type="button"
						onClick={() => removeValue(index)}
						className={styles.removeChipButton}
					>
						<XIcon />
					</button>
				</li>
			))}
		</ul>
	);
};

export default ValuesChips;
