import { Tooltip } from 'primitives/Tooltip';
import { TooltipContentSizeType } from 'primitives/Tooltip/TooltipContentSizeType';
import React, {
	FunctionComponent,
	ReactElement,
	RefAttributes,
	useState,
	forwardRef,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './textArea.module.scss';

export interface TextAreaProps {
	label: string;
	onChange?: (event: React.FormEvent<HTMLTextAreaElement>) => void;
	value?: any;
	errorMessage?: string;
	defaultValue?: any;
	placeholder?: any;
	rows?: number;
	className?: string;
	hideLabel?: boolean;
	tooltip?: string | ReactElement;
	tooltipContentSize?: TooltipContentSizeType;
	onBlur?: (event: React.FormEvent<HTMLTextAreaElement>) => void;
	[otherProps: string]: any;
}

const TextArea: FunctionComponent<TextAreaProps & RefAttributes<any>> =
	forwardRef<any, TextAreaProps>(
		(
			{
				label,
				onChange,
				value,
				defaultValue,
				placeholder,
				errorMessage,
				rows,
				className = '',
				tooltip,
				tooltipContentSize,
				onBlur,
				hideLabel = false,
				...otherProps
			},
			ref,
		) => {
			const [textAreaNodeId] = useState(uuidv4());
			const [labelNodeId] = useState(uuidv4());
			return (
				<div className={`${styles.container} ${className}`}>
					<label
						htmlFor={textAreaNodeId}
						id={labelNodeId}
						className={`${styles.label} ${
							hideLabel && styles.hidden
						}`}
					>
						{label}
					</label>
					<div className={styles.textAreaAndTooltipContainer}>
						<textarea
							id={textAreaNodeId}
							onChange={onChange}
							onBlur={onBlur}
							value={value}
							className={`${styles.textArea} ${
								errorMessage && styles.error
							}`}
							defaultValue={defaultValue}
							placeholder={placeholder}
							rows={rows}
							ref={ref}
							{...otherProps}
						/>
						{tooltip && (
							<Tooltip
								className={styles.tooltip}
								contentSize={tooltipContentSize}
							>
								{tooltip}
							</Tooltip>
						)}
					</div>
					{errorMessage && (
						<div
							className={styles.errorMessage}
							aria-describedby={labelNodeId}
						>
							{errorMessage}
						</div>
					)}
				</div>
			);
		},
	);

TextArea.displayName = 'TextArea';
export default TextArea;
