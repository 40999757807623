import { Label } from 'primitives/Label';
import { forwardRef, FunctionComponent, RefAttributes, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './iconRadioButtons.module.scss';
import IconRadioOptionType from './IconRadioOptionType';

export interface IconRadioButtonsProps {
	label: string;
	onChange?: (value: any) => void;
	value?: any;
	errorMessage?: string | null;
	options: IconRadioOptionType[];
	className?: string;
	disabled?: boolean;
	[otherProps: string]: any;
}

const IconRadioButtons: FunctionComponent<
	IconRadioButtonsProps & RefAttributes<any>
> = forwardRef<any, IconRadioButtonsProps>(
	(
		{
			label,
			onChange,
			value,
			options,
			errorMessage,
			className = '',
			disabled,
			...otherProps
		},
		ref,
	) => {
		const [legendNodeId] = useState(uuidv4());
		return (
			<div className={`${styles.container} ${className}`}>
				<fieldset disabled={disabled}>
					<legend id={legendNodeId}>
						<Label label={label} />
					</legend>
					<div className={styles.optionsContainer}>
						{options.map(
							(
								{ label, value: optionValue, icon, disabled },
								index,
							) => (
								<label key={index} className={styles.option}>
									<input
										type="radio"
										value={optionValue}
										onChange={() =>
											onChange && onChange(optionValue)
										}
										checked={value === optionValue}
										disabled={disabled}
										{...otherProps}
										ref={ref}
									/>
									<span
										className={styles.iconAndLabelContainer}
									>
										<span
											className={`${styles.icon} ${
												errorMessage && styles.error
											}`}
										>
											{icon}
										</span>
										<span className={styles.text}>
											{label}
										</span>
									</span>
								</label>
							),
						)}
					</div>
				</fieldset>
				{errorMessage && (
					<div
						className={styles.errorMessage}
						aria-describedby={legendNodeId}
					>
						{errorMessage}
					</div>
				)}
			</div>
		);
	},
);

IconRadioButtons.displayName = 'IconRadioButtons';
export default IconRadioButtons;
