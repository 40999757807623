import React, {
	FunctionComponent,
	createContext,
	useState,
	useEffect,
	useContext,
} from 'react';
import { getValueFromLocalStorage, saveToLocalStorage } from 'utils';

function themeSwitch(theme: string): void {
	switch (theme) {
		case 'light':
			document.documentElement.setAttribute('color-mode', 'light');
			break;
		case 'dark':
		default:
			document.documentElement.setAttribute('color-mode', 'dark');
			break;
	}
}

const getMediaQueryList = () =>
	window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');
const getBrowserTheme = () => {
	const mql = getMediaQueryList();
	return mql && mql.matches ? 'dark' : 'light';
};

interface ThemeContext {
	hasTheme: string;
	toggleTheme(themeString: string): void;
}

export const useTheme = (): ThemeContext => {
	return useContext(ThemeContext);
};

export const ThemeContext = createContext<ThemeContext>({
	hasTheme: 'dark',
	toggleTheme: () => {},
});

export const ThemeProvider: FunctionComponent = ({ children }) => {
	const [hasTheme, setTheme] = useState('dark');

	useEffect(() => {
		const local = getValueFromLocalStorage('montelProTheme', 'dark');
		const browser = getBrowserTheme();
		if (local) {
			setTheme(local);
			themeSwitch(local);
		} else if (browser) {
			setTheme(browser);
			themeSwitch(browser);
		}
	}, []);

	const toggleTheme = (themeString: string): void => {
		saveToLocalStorage(themeString, 'montelProTheme');
		setTheme(themeString);
		themeSwitch(themeString);
	};

	return (
		<ThemeContext.Provider
			value={{
				hasTheme,
				toggleTheme,
			}}
		>
			{children}
		</ThemeContext.Provider>
	);
};
