import axios from 'axios';
import { useEffect } from 'react';

export const useApplyAxiosAuthInterceptor = (
	refreshAccessToken: () => Promise<string>,
	onRefreshedToken: (token: string) => Promise<void>,
): void => {
	useEffect(() => {
		axios.interceptors.response.use(
			(response: any) => {
				return response;
			},
			async function (error: any) {
				const originalRequest = error.config;

				if (error.response?.status === 401 && !originalRequest._retry) {
					originalRequest._retry = true;

					const access_token = await refreshAccessToken();
					await onRefreshedToken(access_token);
					originalRequest.headers['Authorization'] =
						'Bearer ' + access_token;

					return axios(originalRequest);
				}
				return Promise.reject(error);
			},
		);
	}, []);
};
