import { Tooltip } from 'primitives/Tooltip';
import { Label } from 'primitives/Label';
import { TooltipContentSizeType } from 'primitives/Tooltip/TooltipContentSizeType';
import React, {
	forwardRef,
	FunctionComponent,
	ReactElement,
	RefAttributes,
	useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './input.module.scss';

export interface InputProps {
	label: string;
	onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
	value?: any;
	type?: 'text' | 'number' | string;
	errorMessage?: string;
	defaultValue?: any;
	className?: string;
	tooltip?: string | ReactElement;
	tooltipContentSize?: TooltipContentSizeType;
	placeholder?: string;
	labelWithin?: boolean;
	hideLabel?: boolean;
	disabled?: boolean;
	onBlur?: (event: React.FormEvent<HTMLInputElement>) => void;
	onBeforeInput?: (event: React.FormEvent<HTMLInputElement>) => void;
	[otherProps: string]: any;
}

const Input: FunctionComponent<InputProps & RefAttributes<any>> = forwardRef<
	any,
	InputProps
>(
	(
		{
			label,
			onChange,
			value,
			type,
			defaultValue,
			errorMessage,
			className = '',
			tooltip,
			tooltipContentSize,
			placeholder,
			labelWithin = false,
			hideLabel = false,
			disabled = false,
			onBlur,
			onBeforeInput,
			...otherProps
		},
		ref,
	) => {
		const [inputNodeId] = useState(uuidv4());
		const [labelNodeId] = useState(uuidv4());
		return (
			<div className={`${styles.container} ${className}`}>
				<div
					className={`${styles.inputAndTooltipContainer} ${
						labelWithin ? styles.labelWithin : styles.labelOver
					}`}
				>
					<input
						id={inputNodeId}
						className={`${styles.input} ${
							errorMessage && styles.error
						} ${
							labelWithin ? styles.labelWithin : styles.labelOver
						}`}
						type={type}
						defaultValue={defaultValue}
						placeholder={labelWithin ? ' ' : placeholder}
						onChange={onChange}
						value={value}
						onBlur={onBlur}
						onBeforeInput={onBeforeInput}
						disabled={disabled}
						ref={ref}
						{...otherProps}
					/>
					<label
						htmlFor={inputNodeId}
						id={labelNodeId}
						className={`${styles.label} ${
							hideLabel && styles.hidden
						}`}
					>
						<Label label={label} />
					</label>
					{tooltip && (
						<Tooltip
							className={styles.tooltip}
							contentSize={tooltipContentSize}
						>
							{tooltip}
						</Tooltip>
					)}
				</div>
				{errorMessage && (
					<div
						className={styles.errorMessage}
						aria-describedby={labelNodeId}
					>
						{errorMessage}
					</div>
				)}
			</div>
		);
	},
);

Input.displayName = 'Input';

export default Input;
