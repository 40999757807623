import React, {
	forwardRef,
	FunctionComponent,
	ReactElement,
	RefAttributes,
	useState,
} from 'react';
import isValidEmail from '../utils/isValidEmail';
import ValuesChips from './ValuesChips';
import styles from './multiInput.module.scss';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from 'primitives/Tooltip';
import { Label } from 'primitives/Label';
import { TooltipContentSizeType } from 'primitives/Tooltip/TooltipContentSizeType';

export interface MultiInputProps {
	label: string;
	onChange: (value: any) => void;
	value: string[];
	errorMessage?: string;
	className?: string;
	tooltip?: string | ReactElement;
	tooltipContentSize?: TooltipContentSizeType;
	onBlur?: (event: any) => void;
	disabled?: boolean;
	[otherProps: string]: any;
}

const MultiInput: FunctionComponent<MultiInputProps & RefAttributes<any>> =
	forwardRef<any, MultiInputProps>(
		(
			{
				label,
				onChange,
				value: values = [],
				errorMessage,
				tooltip,
				className = '',
				onBlur,
				disabled,
				...otherProps
			},
			ref,
		) => {
			const [newValue, setNewValue] = useState('');
			const [showNewValueError, setShowNewValueError] = useState(false);

			const addValue = () => {
				if (!newValue) {
					return;
				}
				if (!isValidEmail(newValue)) {
					setShowNewValueError(true);
					return;
				}
				onChange([...values, newValue]);
				setNewValue('');
			};

			const removeValue = (index: number) => {
				const newValues = values.filter(
					(item: any, itemIndex: any) => itemIndex !== index,
				);
				onChange(newValues);
			};

			const handleOnKeyDown = (event: React.KeyboardEvent) => {
				if (
					event.key === 'Enter' ||
					event.key === ' ' ||
					event.key === 'Spacebar' ||
					event.key === ','
				) {
					event.preventDefault();
					addValue();
				}
				if (event.key === 'Backspace' && !newValue) {
					event.preventDefault();
					removeValue(values.length - 1);
				}
			};

			const handleOnChange = (
				event: React.ChangeEvent<HTMLInputElement>,
			) => {
				setNewValue(event.target.value);
				setShowNewValueError(false);
			};

			const [inputNodeId] = useState(uuidv4());
			const [labelNodeId] = useState(uuidv4());
			const hasError = showNewValueError || errorMessage;
			return (
				<div className={`${styles.container} ${className}`}>
					<div className={styles.inputAndTooltipContainer}>
						<div
							className={`${styles.inputWithChipsContainer} ${
								hasError && styles.error
							} ${disabled && styles.disabled} ${
								values.length && styles.hasChips
							}`}
							onKeyDown={handleOnKeyDown}
							onBlur={onBlur}
						>
							<ValuesChips
								values={values}
								removeValue={removeValue}
							/>
							<input
								id={inputNodeId}
								className={`${styles.input} ${
									showNewValueError && styles.error
								}`}
								value={newValue}
								onChange={handleOnChange}
								onBlur={addValue}
								placeholder="add email ..."
								ref={ref}
								disabled={disabled}
								{...otherProps}
							/>
							<label
								htmlFor={inputNodeId}
								id={labelNodeId}
								className={styles.label}
							>
								<Label label={label} />
							</label>
						</div>
						{tooltip && (
							<Tooltip className={styles.tooltip}>
								{tooltip}
							</Tooltip>
						)}
					</div>
					{hasError && (
						<div
							className={styles.errorMessage}
							aria-describedby={labelNodeId}
						>
							{showNewValueError ? 'Invalid email' : errorMessage}
						</div>
					)}
				</div>
			);
		},
	);

MultiInput.displayName = 'MultiInput';
export default MultiInput;
