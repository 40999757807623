import React, { FunctionComponent } from 'react';
import styles from './fullPageLoader.module.scss';

const FullPageLoader: FunctionComponent = () => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
};

export default FullPageLoader;
