import { Tooltip } from 'primitives/Tooltip';
import { TooltipContentSizeType } from 'primitives/Tooltip/TooltipContentSizeType';
import {
	forwardRef,
	FunctionComponent,
	ReactElement,
	RefAttributes,
	useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './checkbox.module.scss';

export interface CheckboxProps {
	label: string | ReactElement;
	checked?: boolean;
	onChange?: (event: any) => void;
	errorMessage?: string;
	className?: string;
	tooltip?: string | ReactElement;
	tooltipContentSize?: TooltipContentSizeType;
	disabled?: boolean;
	[otherProps: string]: any;
}

const Checkbox: FunctionComponent<CheckboxProps & RefAttributes<any>> =
	forwardRef<any, CheckboxProps>(
		(
			{
				label,
				checked,
				onChange,
				errorMessage,
				className = '',
				tooltip,
				tooltipContentSize,
				disabled,
				...otherProps
			},
			ref,
		) => {
			const [labelNodeId] = useState(uuidv4());
			return (
				<div className={`${styles.container} ${className}`}>
					<div className={styles.checkboxAndTooltipContainer}>
						<label
							id={labelNodeId}
							className={disabled ? styles.disabled : ''}
						>
							<input
								type="checkbox"
								onChange={onChange}
								checked={checked}
								ref={ref}
								disabled={disabled}
								{...otherProps}
							/>
							<span className={styles.checkbox} />
							<span className={styles.text}>{label}</span>
						</label>
						{tooltip && (
							<Tooltip
								contentSize={tooltipContentSize}
								className={styles.tooltip}
							>
								{tooltip}
							</Tooltip>
						)}
					</div>
					{errorMessage && (
						<div
							className={styles.errorMessage}
							aria-describedby={labelNodeId}
						>
							{errorMessage}
						</div>
					)}
				</div>
			);
		},
	);

Checkbox.displayName = 'Checkbox';
export default Checkbox;
