import { ErrorStatusCodeEnum } from 'enums/ErrorStatusCodeEnum';

const errorMessageByHttpStatus: { [key in ErrorStatusCodeEnum]: string } = {
	[ErrorStatusCodeEnum.BadRequest]:
		"The server can't process data. Check if your data format is correct or contact support.",
	[ErrorStatusCodeEnum.Unauthorized]: 'Authentication is required.',
	[ErrorStatusCodeEnum.Forbidden]:
		'You might not have the necessary permissions.',
	[ErrorStatusCodeEnum.NotFound]:
		'The requested resource could not be found.',
	[ErrorStatusCodeEnum.Internal]:
		'Something went wrong on the server. Please contact support.',
	[ErrorStatusCodeEnum.ServiceUnavailable]:
		'The server is currently unavailable.',
};

export const getErrorMessageFromHttpStatus = (
	statusCode: number | undefined,
): string => {
	if (!statusCode) return 'Network error.';

	const defaultMessage = 'Something went wrong. Please contact support.';
	return (
		errorMessageByHttpStatus[statusCode as ErrorStatusCodeEnum] ||
		defaultMessage
	);
};
