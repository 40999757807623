export enum Quarter {
	Default = 0,
	Q1 = 1,
	Q2 = 2,
	Q3 = 3,
	Q4 = 4,
}

export enum Month {
	Default = 0,
	Jan = 1,
	Feb = 2,
	Mar = 3,
	Apr = 4,
	May = 5,
	Jun = 6,
	Jul = 7,
	Aug = 8,
	Sep = 9,
	Oct = 10,
	Nov = 11,
	Dec = 12,
}
