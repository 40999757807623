import {
	FunctionComponent,
	useState,
	useEffect,
	useContext,
	createContext,
} from 'react';
import axios from 'axios';
import { IMontelAuthUser, IUser } from 'interfaces';
import { FullPageLoader } from 'primitives';
import { useApplyAxiosAuthInterceptor } from 'hooks/useApplyAxiosAuthInterceptor';

interface AuthRoutes {
	logout: string;
	verifyEmail: (verificationId: string) => string;
	resendEmail: (email: string) => string;
}

export enum AuthStatus {
	UNLOADED = 'unloaded',
	SUCCESS = 'success',
	ERROR = 'error',
	SHOULD_LOGIN = 'should_login',
}

export interface AuthContext {
	accessToken: string;
	authStatus: AuthStatus;
	authRoutes: AuthRoutes;
	user: IUser | null;
	refreshAccessToken: () => Promise<string>;
	fetchUserInfo: (email: string) => Promise<IMontelAuthUser | null>;
	getAccessToken: () => Promise<string>;
	NotLoggedIn: FunctionComponent;
	isLoggedIn: boolean;
}

export const authContext = createContext<AuthContext>({
	accessToken: '',
	authStatus: AuthStatus.UNLOADED,
	authRoutes: {
		logout: '',
		resendEmail: (email) => '',
		verifyEmail: (email) => '',
	},
	user: null,
	refreshAccessToken: async () => '',
	fetchUserInfo: async () => null,
	getAccessToken: async () => '',
	NotLoggedIn: () => <FullPageLoader />,
	isLoggedIn: false,
});

export const AuthProvider: FunctionComponent<{
	authServerUri: string;
	applicationId: string;
	returnUrl: string;
	onNewAccessToken: (token: string) => Promise<void>;
}> = ({
	children,
	authServerUri,
	applicationId,
	returnUrl,
	onNewAccessToken,
}) => {
	const [authStatus, setAuthStatus] = useState<AuthStatus>(
		AuthStatus.UNLOADED,
	);
	const [accessToken, setAccessToken] = useState<string>('');
	const [user, setUser] = useState<IUser | null>(null);

	const refreshAccessToken = async () => {
		try {
			const { data: token } = await axios.get(
				`${authServerUri}/refresh`,
				{
					withCredentials: true,
					params: {
						applicationId: applicationId,
					},
				},
			);

			setAccessToken(token);
			onNewAccessToken(token);

			return token as string;
		} catch (error) {
			setAuthStatus(AuthStatus.SHOULD_LOGIN);
			setAccessToken('');
			console.error(error);

			return '';
		}
	};

	useApplyAxiosAuthInterceptor(refreshAccessToken, async (token: string) => {
		await onNewAccessToken(token);
	});

	const authRoutes: AuthRoutes = {
		logout: `${authServerUri}/logout?applicationId=${applicationId}`,
		verifyEmail: (verificationId: string) =>
			`${authServerUri}/verify-email?applicationId=${applicationId}&verificationId=${verificationId}`,
		resendEmail: (email: string) =>
			`${authServerUri}/resend-email?applicationId=${applicationId}&email=${email}`,
	};

	const NotLoggedIn: FunctionComponent = () => {
		window.location.href = `${authServerUri}/login?applicationId=${applicationId}&returnUrl=${returnUrl}`;

		return <FullPageLoader />;
	};

	const fetchUser = async (): Promise<IUser> => {
		const { data: user } = await axios.get<IUser>(`${authServerUri}/user`, {
			withCredentials: true,
			params: {
				applicationId: applicationId,
			},
		});

		return user;
	};

	const fetchUserInfo = async (email: string): Promise<IMontelAuthUser> => {
		const { data: userInfoData } = await axios.get<{
			user: IMontelAuthUser;
		}>(`${authServerUri}/user-info`, {
			withCredentials: true,
			params: {
				applicationId: applicationId,
				email: email,
			},
		});

		return userInfoData.user;
	};

	const getValidatedAccessToken = async (): Promise<string> => {
		const { data: token } = await axios.get(`${authServerUri}/validate`, {
			withCredentials: true,
			params: {
				applicationId: applicationId,
			},
		});

		return token;
	};

	const initAuth = async () => {
		try {
			const token = await getValidatedAccessToken();

			setAccessToken(token);
			await onNewAccessToken(token);
		} catch (error) {
			setAuthStatus(AuthStatus.SHOULD_LOGIN);
			setAccessToken('');
			console.error(error);

			return;
		}

		try {
			const user = await fetchUser();
			setUser(user);
		} catch (error) {
			setAuthStatus(AuthStatus.ERROR);
			console.error(error);

			return;
		}

		setAuthStatus(AuthStatus.SUCCESS);
	};

	useEffect(() => {
		initAuth();
	}, []);

	return (
		<authContext.Provider
			value={{
				accessToken,
				authRoutes,
				authStatus,
				user,
				refreshAccessToken,
				fetchUserInfo,
				getAccessToken: getValidatedAccessToken,
				NotLoggedIn,
				isLoggedIn:
					authStatus === AuthStatus.SUCCESS && Boolean(accessToken),
			}}
		>
			{children}
		</authContext.Provider>
	);
};

export const useAuth = (): AuthContext => useContext(authContext);
